/* @import '~antd/dist/antd.dark.css'; */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.feature-page-heading {
	font-size: 30px;
	font-weight: bold;
}

.app-loader-center {
    position: absolute !important;
	top: 50% !important;
	left: 50% !important;
	transform: translate(-50%, -50%) !important;
}

.logo {
	width: 300px;
	align-items: center;
	align-content: center;
}

.logo img {
	width: 300px;
	padding: 25px;
}

.app-form-container {
	margin-left: auto;
	margin-right: auto;
}

.timepicker .ant-picker-panel .ant-picker-footer {
	display: none;
}

.home-button-tracknow {
	background-color: rgb(43, 168, 43) !important; 
	border: 1px solid green !important;
}

.home-button-tracknow:hover {
	background-color: rgb(55, 206, 55) !important; 
	border: 1px solid green !important;
}

@media screen and (min-width: 678px) {
	.collections-shortcard-admin-filter {
		position: -webkit-sticky; 
  		position: sticky !important;
		top: 0;
		max-height: 75vh;
		overflow-y: auto;
	}
	
	.collections-shortcard-admin-list {
		margin-left: auto !important;
	}
}
